.carousel-control-prev, .carousel-control-next {
    display: none !important;
}

.icons-list div {
    line-height: 40px;
    white-space: nowrap;
    cursor: default;
    position: relative;
    z-index: 1;
    padding: 5px;
    border-right: 1px solid #252a33;
}
.icons-list div i {
    display: inline-block;
    width: 40px;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    -webkit-transition: font-size 0.2s;
    -moz-transition: font-size 0.2s;
    transition: font-size 0.2s;
}
.icons-list div:hover i {
    font-size: 26px;
}

.form_wizard_tab {
    background-color: #6f7b7d;
    border-radius: .1875rem;
    color: #fff;
    margin-right: 2px;
}

.form_wizard_tab:hover {
    background-color: #6f7b7d;
    border-radius: .1875rem;
    color: #fff;
    margin-right: 2px;
}

.form_wizard_tab.active {
    background-color: #17C2D7!important;
    border-color: transparent!important;
    color: #fff!important;
}

.form_wizard_width {
    width: calc(25% - 2px);
}

.left_dropdown_btn {
	background-color: transparent!important;
    border: 0!important;
    padding-right: 0!important;
    padding-left: 0!important;
}

.left_dropdown_btn:hover {
    background-color: transparent;
    border: 0!important;
    box-shadow: unset!important;
    outline: 0!important;
}

.left_dropdown_btn:active {
    background-color: transparent!important;
    border: 0!important;
    box-shadow: unset!important;
    outline: 0!important;
}

.left_dropdown_btn:focus {
    background-color: transparent!important;
    border: 0!important;
    box-shadow: unset!important;
    outline: 0!important;
}

.user-account .dropdown .dropdown-menu {
    top: 0!important;
}

.sidebar-scroll {
    overflow-y: auto;
    overflow-x: scroll;
    -ms-overflow-style: none;
    height: 100%;
}

.sidebar-scroll::-webkit-scrollbar {
    width: 0;
    background-color: #282b2f;
}

.sidebar-scroll::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #282b2f;
}

.black-editor {
    background-color: #282b2f!important;
}

.text-editor-black .public-DraftEditor-content {
    height: 150PX;
    border: 1px solid #F1F1F1;
    padding: 5px; 
}

.custom_tab.active {
    background-color: #2f3338!important;
    border-color: #2f3338!important;
    color: #a5a8ad!important;
}

.custom_tab.nav-link {
    color: #FFFFFF;
}

.mini_sidebar .mini_sidebar_on .user-account {
    display: none!important;
}