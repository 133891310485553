@import "assets/vendor/bootstrap/css/bootstrap.min.css";
@import "assets/vendor/font-awesome/css/font-awesome.min.css";
@import "assets/vendor/animate-css/vivify.min.css";
@import "assets/vendor/c3/c3.min.css";
@import "assets/vendor/jvectormap/jquery-jvectormap-2.0.3.css";
@import "assets/vendor/bootstrap-multiselect/bootstrap-multiselect.css";
@import "assets/vendor/bootstrap-datepicker/css/bootstrap-datepicker3.min.css";
@import "assets/vendor/bootstrap-colorpicker/css/bootstrap-colorpicker.css";
@import "assets/vendor/multi-select/css/multi-select.css";
@import "assets/vendor/bootstrap-tagsinput/bootstrap-tagsinput.css";
@import "assets/vendor/nouislider/nouislider.min.css";
@import "assets/vendor/nestable/jquery-nestable.css";
@import "assets/css/site.min.css";
@import "assets/css/custom.css";

.theme-cyan #left-sidebar a:hover i, #left-sidebar a:focus i {
    background: #ffc240 !important;
    color: #212529 !important;
}
.theme-cyan #left-sidebar a:hover, #left-sidebar a:focus {
    background: #ffc240 !important;
    color: #212529 !important;
}

tr td img {
  height: 70px;
  width: 70px;
  object-fit: cover;
  border-radius: 100%;
  overflow: hidden;
}

.category-action-td {
  max-width: 250px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}


.custom_table table tbody tr td {
    background: transparent !important;
    font-size: 16px;
    color: #444;
}

.custom_table table tr td, .custom_table table tr th {
    padding: 10px 30px;
}

.custom_table table thead tr th {
    border-bottom: 1px solid #212529 !important;
    color: #212529;
    font-size: 16px;
    font-weight: 700;
    background: transparent !important;
}

.custom-btn {
  background: #c3c3c3;
  color: #555;
  font-weight: bold;
}
.desc{
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.modal{
    background: #000000b0 !important;
}
/* switch toggle csss */

.switch-toggle {
  position: relative;
  line-height: 1;
}

.switch-toggle input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.switch-toggle label {
  width: 50px;
  height: 25px;
  border-radius: 30px;
  cursor: pointer;
  background: green;
  border: 1px solid green;
  margin: 0;
}

.switch-toggle label:after {
  content: "";
  position: absolute;
  top: 47%;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  transition: 0.3s ease-in-out;
  background-color: #fff;
  transform: translate(25px, -50%);
}

.switch-toggle input.false + label {
  background: #fff;
  border: 1px solid maroon;
}

.switch-toggle input.false + label:after{
  transform: translate(5px, -50%);
  background: maroon;
}

.switch-toggle.false label {
  background: #fff;
  border: 1px solid maroon;
}

.switch-toggle.false label:after{
  transform: translate(5px, -50%);
  background: maroon;
}


.modal-body input {
  width: 100%;
  border: 1px solid #e1e8ed;
  border-radius: 5px;
  height: 40px;
  outline: 0;
  padding: 0 10px;
}
.uploadImage{
  width: 170px;
  height: 200px;
  display: flex;
}
.afterUpload{
  display: flex;
  flex-direction: column;
}
.afterUpload button{
  width: 170px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.main{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center ;
}
.main :placeholder-shown{
  text-align: center;
}
.main input{
  width: 500px;
  height: 30px;
  text-align: center;
}
.submit_btn{
  margin-top: 10px;
}

.pagination_section {
  position: absolute;
  top: 850px;
  right: 40%;
}
.list-group {
  padding: 0;
  margin: 0;
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0;
  margin-bottom: 0;
  text-align: center;
}
.seasons_list{
  color: #111; font-family: 'Helvetica Neue', sans-serif;  font-weight: bold; letter-spacing: -1px; line-height: 1; text-align: center;
}
.chapter_heading h1{
  text-align: center;
}
.Modal{
  padding-left: 20px !important;
}



.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a{
  background-color: #ffc240 ;
  border-color: #ffc240 ;
  color: #ffc240;
}
.pagination > li > a{
  border: 1px solid #ffc240 ;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #ffc240 ;
  border-color: #ffc240;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #ffc240
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}




.deleteBtn-category{
  margin-left: 10px;
}
.tableHead-withBtns h2 {
  font-size: 30px;
}
.bookFormTable tbody tr th button{
  width: 200px;
  text-align: left;
}
.bookFormTable .bookImg-th,
.bookFormTable tbody td.bookTab-profImg{
  min-width: 150px;
  text-align: center;
}
.light_version .themesetting .theme_btn{
  display: none;
}
.custom_table.categoryTable {
  /* max-width: 800px;
  margin: 0 auto; */
  box-shadow: 0px 0px 10px 2px #00000078;
}
.usersTable{
  /* max-width: 800px;
  margin: 0 auto; */
  box-shadow: 0px 0px 10px 2px #00000078;
}
.usersTable .pagination{
  padding-left: 10px;
}
.max-width-1200{
  max-width: 1200px;
  margin: 0 auto;
}
.max-width-800{
  max-width: 800px;
  margin: 0 auto;
}
.modalqa .modalHeading{
  font-size: 32px;
  font-weight: 600;
}
.modal-dialog .close{
  color: #000;
  font-size: 35px;
  padding: 5px 10px;
}
.modalqa .modal-footer button.btn.btn-default{
  background-color: #212529;
  color: #fff;
  border-radius: 5px;
  min-width: 90px;
  padding: 8px;
  cursor: pointer;
}
.modalqa .modal-footer button.btn.btn-default:hover{
  background-color: #000;
  transition: all 1s ease-in-out;
}
.ml-auto{
  display: block;
  margin-left: auto;
}
.btn.bookNameBtn{
  min-width: 200px;
  text-align: left;
}
.btn.chpNameBtn{
  min-width: 120px;
  text-align: left;
}
.bookDescpMain p{
  margin-bottom: 10px;
}


/* RESYNC LOADER  */
@keyframes videoGeneratingAnimation {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 15px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.UploadLoaderMain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 76%);
  color: #fff;
  z-index: 999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  pointer-events: none;
  
}
.UploadLoaderMain p .dots{
  position: relative;
    bottom: 10px;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 0 10px;
}
.UploadLoaderMain p span:first-child {
  padding-right: 20px;
}
.UploadLoaderMain p  .dots:nth-last-child(1) {
  background-color: var(--yellow);
  animation: videoGeneratingAnimation 1.4s 0.3s ease-in infinite;
}
.UploadLoaderMain p  .dots:nth-last-child(2) {
  background-color: #000;
  animation: videoGeneratingAnimation 1.4s 0.6s ease-in infinite;
}
.UploadLoaderMain p  .dots:nth-last-child(3) {
  background-color: var(--yellow);
  animation: videoGeneratingAnimation 1.4s 0.9s ease-in infinite;
}


@media only screen and (min-width: 767px) {
  .modalqa .modal-dialog{
    max-width: 700px;
  }
}




@media only screen and (max-width: 1400px) {
  .dash-quan{
    font-size: 20px;
  }
  .categoryTable .table thead tr th {
    font-size: 16px;
    padding-bottom: 10px !important;
  }
  .bookFormTable tbody td img{
    height: 60px;
    width: 60px;
  }
  .modalqa .modalHeading{
    font-size: 30px;
  }
}


@media only screen and (max-width: 1167px) {
  .bookFormTable tbody td img{
    height: 55px;
    width: 55px;
  }
  .modalqa .modalHeading{
    font-size: 26px;
  }
}


@media only screen and (max-width: 991px) {
  .tableHead-withBtns h2{
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .tableHead-withBtns h2{
    font-size: 24px;
  }
}


@media only screen and (max-width: 575px) {
  .jusCent-mob{
    justify-content: center;
  }
  .dashTopCards{
    display: block;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .dash-quan {
    font-size: 16px;
  }
  .dashTopCards .icon-in-bg{
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 400px) {
  .tableHead-withBtns{
    flex-direction: column;
    align-items: flex-start !important;
    margin-bottom: 20px;
  }
}