.main-button {
  position: relative;
  width: 140px;
  height: 32px;
  background: #9367b4;
  border-radius: 10px;
  color: white;
  border: none;
  margin: 20px;
  outline: none;
}
.active-text {
  background-color: yellow;
  font-size: 11px;
  padding: 3px;
  border-radius: 4px;
  margin: 2px;
  text-align: center;
}
.inactive-text{
    background-color: rgb(125, 125, 125);
    font-size:11px;
     padding:3px;
     border-radius:4px;
     margin:2px;
     text-align:center
    }